import ABTesting from '@/libs/ABTesting'

// /assets/images/facebook@3x.png
const FB_IMAGE = 'https://lh3.googleusercontent.com/kgYgeL65iAt_X7k_COiLZhdkXlJxLMLza88fT9TCghEnpJzMKT5anofrwEELCGJhMEX5_a8PjXIfP_mECV7cil250wKgRLaRu6-YfRxmBQ=s60'

// /assets/images/food-icon.png
const FOOD_ICON = 'https://lh3.googleusercontent.com/5egqvTX37QdoHGjg9beYxupSk_zCj5ISEuuiTbHXjyCzTTAtlr7tRBXy-yb6_XpmhBdw1j4EQQumylOKnCXCaS7iqiQjutgk3KRLlCu9v7fU=s40'

// /assets/images/instagram@3x.png
const IG_ICON = 'https://lh3.googleusercontent.com/l-Wa-Vfx7DvyfQYJ4Gn7e39c8EkLm1gKt33uxSYCcFZJayBh-6nqxTcs2HSkFi3zHSza0uMKr0MuFweEhbYLNflql8ZnM4MZvXamiAhEaN4=s40'

const IFOODIE_IMAGE = 'https://lh3.googleusercontent.com/tEpSYmkyJ40d8r9gxMVz4iAA0xWND8mMrEvw2Fw13y83CDbKNSC81jSEG6KCyioZVnXrZbYaQm5vmfwW05CKcEA7LhBN88U=s600'

// const PICK_100_IMAGE = 'https://lh3.googleusercontent.com/Bvv60ftou-yyRw00jgigMVxIlHjBIIzMY3Pr58k1FmnhlWksb8L98-qqxyBsiHquO7snQQkf0Te13mTlbeQEP37D3a7RIyQnB8gK0c-ORS_L_A=s400'
const PICK_100_IMAGE = 'https://lh3.googleusercontent.com/73j3Qh9a-sWl8xsNq1CoBDWwSuwmQPZ6BVLInLv4YGGzvjtCw9xuaJQTZwhbST4rsbXAK5VHTN4hGA10cWEzfTPJwzuuSqkJWcBHjgDo_hPR=s100'
// const PICK_100_IMAGE = 'https://lh3.googleusercontent.com/QPO8pjnyjPXHT9Nqz9i0_GKK0ZNfYJt04FIp0V2IbTduuHS1iUpjAJW0fi75CMgHRQvPCU9EAJ6kAxhSuW2p6arCK9S5XGgFM-1XUg6vnZk=s400'
const PICK_100_IMAGE_B = 'https://lh3.googleusercontent.com/lEeEXNwpGOjnd-sv12MZfhhzNQgzMXjAjDtXvo5lZN6oir5HQpDyFpi_flP4LGAsoqLUUwrRfoBxLLEalMgfW1jQjzyHpWRXHtHu74rI6ch8=s100'

const getPick100Image = (abTesting) => {
  if (abTesting == ABTesting.TESTING) {
    return PICK_100_IMAGE
  }
  return PICK_100_IMAGE_B
}

const EMPTY_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='

export { FB_IMAGE, FOOD_ICON, IG_ICON }
export { EMPTY_IMAGE, IFOODIE_IMAGE, PICK_100_IMAGE, getPick100Image }