import { useEffect } from 'react'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import GAEventTracker from '@/libs/GAEventTracker'
import ABTesting from '@/libs/ABTesting'

import { getPick100Image } from '@/libs/staticImage'

function HundredPick({
  sx,
  text='百選店',
  trackLabel = '預設',
  trackCategory = '預設',
  withIcon = false,
  size = 'small',
}) {
  const pick100Image = getPick100Image(ABTesting.getABTesting({ exp: ABTesting.EXP_PICK100 }))

  useEffect(() => {
    ABTesting.sendEvent({ exp: ABTesting.EXP_PICK100 })
  }, [])

  const icon = withIcon ? (
    <img
      src={pick100Image}
      width="43"
      style={{
        marginLeft: 10,
        marginBottom: 10,
        left: 0,
        filter: 'drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.5))',
      }}
    />
  ) : null

  return (
    <Tooltip
      title="「百選店」是由愛食記官方推薦的優質店家"
      placement="top-start"
      onMouseEnter={() => {
        GAEventTracker.sendEvent({
          action: '進入百選店',
          label: `${trackLabel} 進入百選店`,
          category: trackCategory,
        })
      }}>
      <a href="/pick/taipei" target='_blank'>
        <Box
          sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', ...sx }}
          onClick={() => {
            GAEventTracker.sendEvent({
              action: '點擊百選店',
              label: `${trackLabel} 點擊百選店${withIcon ? '有icon' : '無icon'}`,
              category: trackCategory,
            })
          }}>
          {icon}
          <Typography
            variant="h6"
            sx={{
              cursor: 'pointer',
              fontSize: size == 'small' ? 11 : 12,
              color: '#fff',
              borderBottom: '2px solid #B5A380',
              border: '1px solid #B5A380',
              backgroundColor: '#21232A',
              padding: size == 'small' ? '0px 4px' : '4px 6px',
              borderRadius: 1,
            }}>
            {text}
          </Typography>
        </Box>
      </a>
    </Tooltip>
  )
}

export default HundredPick
