const NProgress = require('nprogress')
const routes = require('next-routes')

const exportRoutes = routes()
  // New Route Rule - base on the folder structure under "pages"
  // e.g.: case, panel
  .add({ name: 'home', pattern: '/', page: 'index' })
  .add({
    // Legacy url
    name: 'blogger-home-legacy',
    pattern: '/user',
    page: 'blogger',
  })
  .add({
    // Legacy url
    name: 'blogger-dashboard-legacy',
    pattern: '/user/:userId/publish',
    page: 'bloggerDashboard',
  })
  .add({
    name: 'blogger-home',
    pattern: '/blogger',
    page: 'blogger',
  })
  .add({
    name: 'blogger-dashboard',
    pattern: '/blogger/publish',
    page: 'bloggerDashboard',
  })
  .add({
    name: 'blogger-login',
    pattern: '/blogger/login',
    page: 'bloggerLogin',
  })
  .add({
    name: 'blogger-new-blog',
    pattern: '/blogger/blogs/edit',
    page: 'editBlog',
  })
  .add({
    name: 'blogger-edit-blog',
    pattern: '/blogger/blogs/edit/:blogId',
    page: 'editBlog',
  })
  .add({
    name: 'user-iframe-legacy',
    pattern: '/user/:userId/iframe',
    page: 'userIframe',
  })
  .add({
    name: 'user-iframe',
    pattern: '/u/:userId/iframe',
    page: 'userIframe',
  })
  .add({
    name: 'business-apply-confirm',
    pattern: '/business/apply/:applyId',
    page: 'confirmBusinessApplication',
  })
  .add({
    name: 'business-apply',
    pattern: '/business/apply',
    page: 'businessApply',
  })
  .add({
    name: 'business-dashboard-login',
    pattern: '/business/login',
    page: 'business/login',
  })
  .add({
    name: 'business-dashboard-regist',
    pattern: '/business/regist',
    page: 'business/regist',
  })
  .add('/business', 'business')
  .add({
    name: 'business-dashboard-detail',
    pattern: '/business/:page/:item',
    page: 'business',
  })
  .add({
    name: 'business-dashboard',
    pattern: '/business/:page',
    page: 'business',
  })
  .add('/promote', 'promote')
  .add('/promote/:city', 'promote')
  .add('/card/:cardId', 'card')
  .add('/campaign-hakka', 'campaign')
  .add('/reservation/:city', 'reservation')
  .add('/pick/:city', 'pick')
  .add('/restaurant/:restaurantId([0-9a-z]+)-:title*', 'restaurant')
  .add('/restaurant/:restaurantId', 'restaurant')
  .add('/restaurant/:restaurantId/iframe', 'restaurantIframe')
  .add('/post/:postId([0-9a-z]+)-:title*', 'post')
  .add('/post/:postId', 'post')
  .add('/:lang([a-z]+)/post/:postId([0-9a-z]+)-:title*', 'post')
  .add('/:lang([a-z]+)/post/:postId', 'post')
  .add('/c/submit', 'submit')
  .add('/c/list', 'community')
  .add('/c/rss', 'articleRss')
  .add({
    name: 'article-list',
    pattern: '/c/list/:category',
    page: 'community',
  })
  .add('/c/list/p/:articleId/edit', 'editArticle')
  .add('/c/list/p/:articleId([0-9a-z]+)-:title*', 'article')
  .add('/c/list/p/:articleId', 'article')
  .add({
    name: 'search-sublocation',
    pattern: '/explore/:location/:sublocation/list/:keyword',
    page: 'explore',
  })
  .add({
    name: 'search-location',
    pattern: '/explore/:location/list/:keyword',
    page: 'explore',
  })
  .add({
    name: 'search-location-sub',
    pattern: '/explore/:location/list/:keyword/:subkeyword',
    page: 'explore',
  })
  .add({
    name: 'search-keyword',
    pattern: '/explore/list/:keyword',
    page: 'explore',
  })
  .add({
    name: 'search-user',
    pattern: '/explore/user/:mapUserId',
    page: 'explore',
  })
  .add({
    name: 'search-restaurant',
    pattern: '/explore/restaurant/:mapRestId',
    page: 'explore',
  })
  .add({
    name: 'search-keyword-sub',
    pattern: '/explore/list/:keyword/:subkeyword',
    page: 'explore',
  })
  .add({
    name: 'search-sublocation-empty',
    pattern: '/explore/:location/:sublocation/list',
    page: 'explore',
  })
  .add({
    name: 'search-location-empty',
    pattern: '/explore/:location/list',
    page: 'explore',
  })
  .add({
    name: 'search-keyword-empty',
    pattern: '/explore/list',
    page: 'explore',
  })
  .add({ name: 'downloadapp', pattern: '/download-app', page: 'downloadapp' })
  .add({ name: 'news', pattern: '/news/:city', page: 'news' })
  .add({ name: 'users', pattern: '/u/:userId', page: 'user' })
  .add({ name: 'users-saved', pattern: '/u/:userId/:tab', page: 'user' })
  .add({ name: 'user-legacy', pattern: '/user/:userId', page: 'user' })

exportRoutes.Router.onRouteChangeStart = () => {
  NProgress.start()
  NProgress.configure({
    minimum: 0.3,
  })
}
exportRoutes.Router.onRouteChangeComplete = () => NProgress.done()
exportRoutes.Router.onRouteChangeError = () => NProgress.done()

module.exports = exportRoutes
