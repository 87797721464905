import React, { Component } from 'react'
import styles from '@/styles/Footer.module.js'
import config from '@/config'
import GAEventTracker from '@/libs/GAEventTracker'

import ABTesting from '@/libs/ABTesting'

const FANPAGE_URL = 'https://www.facebook.com/ifoodie.tw'
const INSTAGRAM = 'https://instagram.com/ifoodie.tw'
const CONTACT_EMAIL = 'support@tsunamiworks.com'
const TERM_OF_SERVICE_URL =
  '/terms'
const PRIVACY_URL =
  '/privacy'
const IOS_APP_URL =
  'https://apps.apple.com/tw/app/%E6%84%9B%E9%A3%9F%E8%A8%98-%E5%8F%B0%E7%81%A3%E7%B2%BE%E9%81%B8%E9%A4%90%E5%BB%B3-x-%E7%BE%8E%E9%A3%9F%E5%84%AA%E6%83%A0/id757992884'
const ANDROID_APP_URL =
  'https://play.google.com/store/apps/details?id=com.djages.taipeifoodblogs'

class Footer extends Component {
  componentDidMount() {
    // function translatePageTo(language) {
    //   var translateElement = document.querySelector('.goog-te-combo');
    //   if (translateElement) {
    //     translateElement.value = language;
    //     translateElement.dispatchEvent(new Event('change'));
    //   }
    // }
    // const addGoogleTranslateScript = () => {
    //   if (document.querySelector('.goog-te-combo')) return // 防止重複加載

    //   new window.google.translate.TranslateElement(
    //     {
    //       pageLanguage: 'zh-TW',
    //       includedLanguages: 'en,zh-TW,ja,ko',
    //       layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
    //     },
    //     'google_translate_element'
    //   )

    //   setTimeout(() => {
    //     // document.querySelector('.skiptranslate').remove();
    //   }, 1000)
    // }

    // const script = document.createElement('script');
    // script.src = '//translate.google.com/translate_a/element.js?cb=addGoogleTranslateScript';
    // script.async = true;
    // document.body.appendChild(script);

    // window.addGoogleTranslateScript = addGoogleTranslateScript;
  }
  render() {
    return (
      <div className="footer-frame">
        <style jsx>{styles}</style>
        <div className="container">
          <div className="col">
            <div>相關連結</div>
            <ul>
              <li>
                <a
                  onClick={this._handleFooterClick('愛食記粉絲專頁')}
                  target="_blank"
                  href={FANPAGE_URL}>
                  愛食記粉絲專頁
                </a>
              </li>
              <li>
                <a
                  onClick={this._handleFooterClick('愛食記IG')}
                  target="_blank"
                  href={INSTAGRAM}>
                  Instagram
                </a>
              </li>
              <li>
                <a
                  onClick={this._handleFooterClick('聯絡我們')}
                  rel="nofollow"
                  href={`mailto:${CONTACT_EMAIL}`}>
                  聯絡我們
                </a>
              </li>
              <li>
                <a
                  onClick={this._handleFooterClick('服務條款')}
                  target="_blank"
                  rel="nofollow"
                  href={TERM_OF_SERVICE_URL}>
                  服務條款
                </a>
              </li>
              <li>
                <a
                  onClick={this._handleFooterClick('隱私政策')}
                  target="_blank"
                  rel="nofollow"
                  href={PRIVACY_URL}>
                  隱私政策
                </a>
              </li>
            </ul>
          </div>
          <div className="col">
            <div>愛食記應用程式</div>
            <ul>
              <li>
                <a
                  onClick={this._handleFooterClick('iOS App')}
                  target="_blank"
                  href={IOS_APP_URL}>
                  iOS App
                </a>
              </li>
              <li>
                <a
                  onClick={this._handleFooterClick('Android App')}
                  target="_blank"
                  href={ANDROID_APP_URL}>
                  Android App
                </a>
              </li>
            </ul>
          </div>
          <div className="col">
            <div>店家專區</div>
            <ul>
              <li>
                <a
                  onClick={this._handleFooterClick('加入愛食記')}
                  target="_blank"
                  className="store-link"
                  href={'/why-ifoodie'}>
                  如何加入愛食記?
                </a>
              </li>
              <li>
                <a
                  onClick={this._handleFooterClick('店家專區')}
                  target="_blank"
                  className="advertise-link"
                  href={'/why-ifoodie'}>
                  行銷方案介紹
                </a>
              </li>
              <li>
                <a
                  onClick={this._handleFooterClick('聯繫部落客')}
                  target="_blank"
                  className="blogger-list"
                  href={'/why-ifoodie#feature-highlights'}>
                  聯繫部落客
                </a>
              </li>
              <li>
                <span style={{ opacity: 0.2, fontSize: 12 }}>
                  v: {config.appVersion}, {ABTesting.getRandomNum()}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="copyright">Copyright ©TsunamiWorks 2013-2025 ❤️ </div>
        <div id='google_translate_element' />
      </div>
    )
  }
  _handleFooterClick(label) {
    return () => {
      GAEventTracker.sendEvent({
        category: '點擊Footer',
        action: '點擊Footer內容',
        label,
      })
    }
  }
}

export default Footer
