import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import geolib from 'geolib'


import styles from '@/styles/Restaurant/RestaurantItem.module.js'
import RatingStar from '@/components/Search/RatingStar'

import { Link } from '@/routes'
import cleanStringForUrl from '@/libs/cleanStringForUrl'
import PlaceIcon from '@mui/icons-material/Place'
import Tooltip from '@mui/material/Tooltip'
import GAEventTracker from '@/libs/GAEventTracker'
import HundredPick from '@/components/utils/HundredPick'

const PRICE_LEVEL_HINTS = ['', '$', '$$', '$$$', '$$$$']

class RestaurantItem extends PureComponent {
  constructor(props) {
    super(props)
    this._getDistance = this._getDistance.bind(this)
    this._getImageUrl = this._getImageUrl.bind(this)
    this._handleClickTrackEvent = this._handleClickTrackEvent.bind(this)
  }
  componentDidMount() {
    const { restaurantItem } = this.props
    if (!!restaurantItem.getIn(['ad', 'id'])) {
      GAEventTracker.sendEvent({
        category: '餐廳item廣告',
        action: '餐廳item廣告impression',
        label: `${restaurantItem.getIn(['ad', 'id'])}`,
      })
    }
  }
  render() {
    const {
      restaurantItem,
      getClickAdGAProps,
      index,
      refLat,
      refLng,
      className,
      showImage,
      deferShowImage,
    } = this.props
    const gaProps = getClickAdGAProps ? getClickAdGAProps(restaurantItem) : null
    return (
      <div className={`restaurant-item-outer ${className}`}>
        <style jsx>{styles}</style>
        <div className="restaurant-item">
          {!restaurantItem.getIn(['ad', 'id']) &&
          (index === 0 || index === 1 || index === 2) ? (
            <div className="top">
              <img
                src={(deferShowImage && showImage) ? this._getTopImage(index):"data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="}
                data-src={deferShowImage ? null: this._getTopImage(index)}
              />
            </div>
          ) : null}

          <a
            href={this._getRestaurantUrl()}
            onClick={this._handleClickTrackEvent}
            target="_self">
            <img
              src={(deferShowImage && showImage) ? this._getImageUrl():"data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="}
              className="cover lazyload"
              onError={(e) => {
                e.target.src =
                  'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
              }}
              data-sizes="auto"
              data-src={deferShowImage ? null: this._getImageUrl()}
              alt={restaurantItem.get('name')}
            />
          </a>
          <div className="info">
            <div className="name-outer">
              <a
                href={this._getRestaurantUrl()}
                className="name-link"
                target="_self"
                onClick={this._handleClickTrackEvent}>
                <h3 className="name">{restaurantItem.get('name')}</h3>
              </a>
              {!!restaurantItem.get('isPick') ?
              (
                <HundredPick trackLabel={restaurantItem.get('name')} trackCategory='側邊欄位' />
              ) : !!restaurantItem.getIn(['ad', 'id']) ? (
                <Tooltip
                  title="贊助的廣告，點擊前往觀看方案介紹"
                  placement="bottom">
                  <a
                    className="ad-icon"
                    href="/why-ifoodie"
                    target="_blank">
                    廣告
                  </a>
                </Tooltip>
              ) : null}
            </div>
            <div className="short">
              {restaurantItem.get('aiShort')}
            </div>
            <div className="count">
              <div className="rating">
                <RatingStar
                  rating={restaurantItem.get('rating')}
                  small={true}
                />
              </div>
              <a href={`${this._getRestaurantUrl()}#reviews`}>
                <div className="visit-cnt">
                  （{restaurantItem.get('visitCnt')}則評論）
                </div>
              </a>
            </div>
            <div className="category-outer">
              <div className="avg-price-category">
                {!!restaurantItem.get('avgPrice') && (
                  <span>
                    <span className="avg-price">
                      均消 ${restaurantItem.get('avgPrice')}
                    </span>
                    ・
                  </span>
                )}
                {!restaurantItem.get('avgPrice') &&
                  !!restaurantItem.get('priceLevel') && (
                    <span className="avg-price">
                      {PRICE_LEVEL_HINTS[restaurantItem.get('priceLevel')]}・
                    </span>
                  )}
                {!!restaurantItem.getIn(['categories', 0]) && (
                  <span>
                    <Link
                      route={this._getRestaurantItemExploreUrl(
                        restaurantItem,
                        restaurantItem.getIn(['categories', 0])
                      )}>
                      <a className="cat-link" target="_blank">
                        <span className="city">
                          {this._getCleanRestaurantItemCityArea(restaurantItem)}
                        </span>
                        <Tooltip
                          title={`搜尋${this._getCleanRestaurantItemCityArea(
                            restaurantItem
                          )}${restaurantItem.getIn(['categories', 0])}`}
                          placement="right">
                          <span className="cat">
                            {restaurantItem.getIn(['categories', 0])}
                          </span>
                        </Tooltip>
                      </a>
                    </Link>
                  </span>
                )}
                {!restaurantItem.get('avgPrice') &&
                  !restaurantItem.get('priceLevel') &&
                  !!restaurantItem.getIn(['categories', 1]) && (
                    <span>
                      ・
                      <Link
                        route={this._getRestaurantItemExploreUrl(
                          restaurantItem,
                          restaurantItem.getIn(['categories', 1])
                        )}>
                        <a>{restaurantItem.getIn(['categories', 1])}</a>
                      </Link>
                    </span>
                  )}
              </div>
              {refLat && refLng && (
                <div className="distance">
                  <PlaceIcon className="icon-place" />
                  <span>{this._getDistance({ restaurantItem })}</span>
                </div>
              )}
            </div>
          </div>
          {/* {gaProps && (
            <a
              className="click-tracker"
              href={this._getRestaurantUrl()}
              target="_self"
              onClick={this._handleClickTrackEvent}
              {...gaProps}
            />
          )} */}
        </div>
      </div>
    )
  }
  _getTopImage(index) {
    return `/assets/images/top${index + 1}.png`
  }
  _getRestaurantUrl() {
    const { restaurantItem } = this.props
    let name = cleanStringForUrl(restaurantItem.get('name'))
    return `/restaurant/${restaurantItem.get('id')}-${name}`
  }
  _handleClickTrackEvent() {
    const { restaurantItem, trackAdClick } = this.props
    if (restaurantItem.getIn(['ad', 'id'])) {
      GAEventTracker.sendEvent({
        category: '餐廳item廣告',
        action: '點選餐廳item廣告',
        label: `${restaurantItem.getIn(['ad', 'id'])}`,
      })
      trackAdClick({ adId: restaurantItem.getIn(['ad', 'id']) })
    }

    GAEventTracker.sendEvent({
      action: '點擊餐廳Item',
      label: `${restaurantItem.get('name')}`,
      category: 'Restaurant頁面',
    })
  }
  _getDistance() {
    const { refLat, refLng, restaurantItem } = this.props
    const distance = geolib.getDistance(
      { latitude: refLat, longitude: refLng },
      {
        latitude: restaurantItem.get('lat'),
        longitude: restaurantItem.get('lng'),
      }
    )
    if (distance === 0) {
      return '0公尺'
    } else if (distance < 1000) {
      return `${distance}公尺`
    } else {
      return `${Math.round((distance / 1000) * 100) / 100}公里`
    }
  }
  _getImageUrl() {
    const { restaurantItem } = this.props
    let coverUrl = restaurantItem.get('coverUrl')
    return coverUrl.replace('=s600', '=s200')
  }
  _getCleanRestaurantItemCityArea(restaurantItem) {
    let city = '台北市'
    if (restaurantItem.get('city')) {
      city = restaurantItem.get('city').replace('市', '').replace('縣', '')
    }
    const adminName = restaurantItem.get('adminName')
    if (city && adminName) {
      return `${city}${adminName}`
    } else {
      return city
    }
  }
  _getRestaurantItemExploreUrl(restaurantItem, category) {
    const cleanCategory = category ? cleanStringForUrl(category) : ''
    const city = restaurantItem.get('city')
    const adminName = restaurantItem.get('adminName')
    if (city && adminName && cleanCategory) {
      return `/explore/${city}/list/${adminName}${cleanCategory}`
    } else if (city && cleanCategory) {
      return `/explore/${city}/list/${cleanCategory}`
    } else {
      return '/explore/list'
    }
  }
}

RestaurantItem.propTypes = {
  refLat: PropTypes.number,
  refLng: PropTypes.number,
  restaurantItem: PropTypes.instanceOf(Map).isRequired,
  getClickAdGAProps: PropTypes.func,
  index: PropTypes.number,
  trackAdClick: PropTypes.func,
  className: PropTypes.string,
}

RestaurantItem.defaultProps = {
  className: '',
  deferShowImage: false,
  showImage: true,
}

export default RestaurantItem
